import { Component, Host, h, Element, Prop, State } from '@stencil/core';
import { Sortable, Swap } from 'sortablejs/modular/sortable.core.esm';

Sortable.mount(new Swap());

@Component({
  tag: 'x-sortable',
  styleUrl: 'x-sortable.sass',
})
export class XSortable {
  @Element() el: HTMLElement;

  @Prop({ reflect: true }) container: boolean;
  @Prop({ reflect: true }) item: boolean;
  @Prop({ reflect: true }) handle: boolean;
  @Prop({ reflect: true }) ignore: boolean;
  @Prop({ reflect: true }) name: string;
  @Prop({ reflect: true }) placement: string = 'left';
  @Prop() offset: string = '0,0';

  @State() value: string = '';

  sortable;

  private updateValue = () => {
    this.value = this.sortable.toArray().join(',');
  };

  componentWillLoad() {
    if (this.container) {
      this.sortable = new Sortable(this.el, {
        draggable: 'x-sortable[item]',
        handle: 'x-sortable[handle]',
        filter: 'x-sortable[ignore]',
        preventOnFilter: true,
        // invertSwap: true,
        swap: true,
        animation: 150,
        direction: 'vertical',
        onEnd: () => {
          this.updateValue();
        },
      });

      this.updateValue();
    }
  }

  render() {
    let style = null;

    if (this.handle && this.offset) {
      const [x, y] = this.offset.split(',');

      style = {
        marginLeft: `${x}px`,
        marginTop: `${y}px`,
      };
    }

    return (
      <Host style={style}>
        <slot></slot>
        {!!this.container && <input type="hidden" name={this.name} value={this.value} />}
      </Host>
    );
  }
}
