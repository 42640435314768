x-sortable[item]
  display: block
  position: relative
  &.sortable-swap-highlight
    background-color: var(--surface-light-grey)
  &:hover
    background-color: var(--surface-light-grey)
    x-sortable[handle]
      display: block

x-sortable[handle]
  position: absolute
  font-size: 0
  line-height: 0
  cursor: move
  display: none
  &[placement="left"]
    left: 0
    top: 50%
    transform: translateY(-50%)
